<template>
  <div>
    <div class="all_context">
      <div class="all_context_flex">
        <div class="storeCenter_item_top_left">
          <div class="banner_box">
            <div class="activity_state two">
              <span>{{ headData.clueStage }}</span>
            </div>
            <img v-if="headData.clueImage" :src="headData.clueImage" alt="" />
          </div>
          <div class="storeCenter_item_top_left_flex">
            <div style="display: flex; align-items: center">
              <div class="clue_name">
                {{ headData.clueName }}
              </div>
              <div class="type_culb" v-if="!headData.clueCost">
                <img src="../../../../assets/image/Vector.png" alt="" />
                <span style="margin-left: 5px">免费</span>
              </div>
              <div class="type_culb" v-else>
                <span style="margin-right: 10px">押金￥{{ headData.deposit }}</span>
                <span>线索费￥{{ headData.clueCost }}</span>
              </div>
            </div>
            <div style="height: 25px" v-if="headData.clueTable">
              <el-tag
                size="mini"
                effect="plain"
                style="margin-right: 10px"
                v-for="(o, index) in newClueTableList(headData.clueTable).slice(0, 2)"
                :key="index"
                >{{ o }}</el-tag
              >
              <el-popover placement="right" width="400" trigger="hover">
                <div>
                  <el-tag
                    v-for="(i, index) in newClueTableList(headData.clueTable)"
                    :key="index"
                    size="mini"
                    effect="plain"
                    style="margin: 5px"
                  >
                    {{ i }}
                  </el-tag>
                </div>
                <el-tag
                  size="mini"
                  effect="plain"
                  slot="reference"
                  style="margin-right: 10px"
                  v-show="newClueTableList(headData.clueTable).length > 3"
                  >{{ newClueTableList(headData.clueTable).length }}+</el-tag
                >
              </el-popover>
            </div>
            <div style="display: flex" class="row">
              <div class="corp_name">{{ headData.companyFullName }}</div>
              <div style="padding: 0 10px">
                {{ headData.customerContactPerson }}
              </div>
            </div>
            <div class="bottom_row">
              <span style="margin-right: 20px">
                所在地区：{{ headData.province }}-{{ headData.city
                }}<span v-if="headData.district">-{{ headData.district }}</span>
              </span>
              <span
                >有效日期：{{ headData.clueStartTime }}至
                <span v-if="headData.estimatedCompletionTime">{{
                  headData.estimatedCompletionTime
                }}</span>
                <span v-else>---</span>
              </span>
            </div>
          </div>
        </div>
        <div class="storeCenter_item_top_right">
          <el-button type="primary" @click="goLevel">返回上一级</el-button>
        </div>
      </div>
      <div class="context">
        <div class="state">
          <div class="left">
            <div style="margin-right: 20px">当前时间共推出{{ total }}个服务商</div>
            <div style="display: flex; align-items: center; margin-right: 30px">
              按跟进状态
              <div class="main">
                <span
                  class="arrUp"
                  @click="clickUp1"
                  :style="{ 'border-bottom-color': orderinfo.color1 }"
                ></span>
                <span
                  class="arrDown"
                  @click="clickDown2"
                  :style="{ 'border-top-color': orderinfo.color2 }"
                ></span>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="seach_box">
              <el-input
                placeholder="请输入企业全称"
                v-model="query.keyWord"
                class="input-with-select"
                clearable
                @change="changeSearchangeSearch"
              >
                <el-button class="seach" slot="append" @click="changeSearchangeSearch"
                  >搜索</el-button
                >
              </el-input>
            </div>
          </div>
        </div>
        <div class="table">
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            v-loading="loading"
            element-loading-spinner="el-icon-loading"
            element-loading-text="加载中..."
            @selection-change="handleSelectionChange"
            :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
          >
            <el-table-column label="序号" align="center" width="80px" type="index">
            </el-table-column>
            <el-table-column label="企业名称" align="center">
              <template slot-scope="scope">{{ scope.row.companyFullName }}</template>
            </el-table-column>
            <el-table-column label="企业网址" align="center" :show-overflow-tooltip="true">
              <template v-slot="{ row }">
                <a target="_blank" :href="row.officialWebsite">{{
                  row.officialWebsite
                }}</a></template
              >
            </el-table-column>
            <el-table-column
              label="企业简介"
              width="200"
              align="center"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <el-popover placement="right" width="200" trigger="hover">
                  <div>{{ scope.row.enterpriseIntro }}</div>
                  <div slot="reference" style="padding: 0 10px">
                    {{ truncatedText(scope.row.enterpriseIntro) }}
                  </div>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column label="所在城市" align="center">
              <template slot-scope="scope">{{ scope.row.province }}-{{ scope.row.city }}</template>
            </el-table-column>
            <el-table-column label="企业规模" width="80" align="center">
              <template slot-scope="scope">{{ scope.row.enterpriseScale }}</template>
            </el-table-column>
            <el-table-column label="企业联系人" align="center">
              <template slot-scope="scope">
                <div class="contact_person" @click="contactClick(scope.row)">
                  {{ scope.row.realName || '暂无' }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="联系人职位" align="center">
              <template slot-scope="scope">{{ scope.row.position }}</template>
            </el-table-column>
            <el-table-column label="电话" align="center">
              <template slot-scope="scope">{{ scope.row.phone }}</template>
            </el-table-column>
            <el-table-column label="服务商报价" align="center">
              <template slot-scope="scope">{{ scope.row.quotation }}</template>
            </el-table-column>
            <el-table-column label="关联度" align="center" sortable width="86">
              <template slot-scope="scope">
                <div class="progress">{{ scope.row.correlation }}</div>
              </template>
            </el-table-column>
            <el-table-column label="联系情况" align="center" :show-overflow-tooltip="true">
              <template slot-scope="scope">{{ scope.row.contactContent }}</template>
            </el-table-column>
            <el-table-column label="客户对接情况" align="center">
              <template slot-scope="scope">{{ scope.row.contactStatus }}</template>
            </el-table-column>
            <el-table-column label="跟进状态" align="center">
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.followUpStatus"
                  placeholder="请选择"
                  @change="changeFollowUpStatus(scope.row)"
                >
                  <el-option label="推进中" :value="1"> </el-option>
                  <el-option label="待推进" :value="2"> </el-option>
                  <el-option label="取消推进" :value="3"> </el-option>
                </el-select>
              </template>
            </el-table-column>
          </el-table>

          <div class="Pagination">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="query.pageNum"
              :page-sizes="[10, 30, 50]"
              :page-size="query.size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- 企业联系人弹窗 -->
    <el-dialog
      title="企业联系人"
      :before-close="contactClose"
      :visible.sync="contactDia"
      width="50%"
    >
      <div class="contact_dia">
        <el-table
          :data="contactList"
          tooltip-effect="dark"
          :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
          class="con_table"
          @row-click="singleElection"
          ref="multipleTableCon"
        >
          <el-table-column align="center" width="55" label="选择">
            <template slot-scope="scope">
              <!-- 可以手动的修改label的值，从而控制选择哪一项 -->
              <el-radio class="radio" v-model="templateSelection" :label="scope.row.id"
                >&nbsp;</el-radio
              >
            </template>
          </el-table-column>
          <!-- <el-table-column type="selection" reserve-selection width="55" align="center" /> -->
          <el-table-column prop="realName" label="企业联系人" align="center"></el-table-column>
          <el-table-column prop="position" label="企业联系人职位" align="center"></el-table-column>
          <el-table-column prop="phone" label="联系电话" align="center"></el-table-column>
        </el-table>
        <div class="Pagination">
          <el-pagination
            @size-change="handleSizeChangeCon"
            @current-change="handleCurrentChangeCon"
            :current-page="contactQueryInfo.pageNum"
            :page-sizes="[10, 30, 50]"
            :page-size="contactQueryInfo.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalContact"
          >
          </el-pagination>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="contactClose">取 消</el-button>
        <el-button type="primary" @click="contactSubmit">确 定</el-button>
      </span></el-dialog
    >
  </div>
</template>
<script>
import {
  listTopById,
  selectClueCompanyPage,
  updateClueCompanyFreeze,
  listPagePeople,
  insertClueContacts
} from '../../../../api/clue.js'
const defaultQueryInfoContact = Object.freeze({
  companyId: '',
  orders: 1,
  pageNum: 1,
  pageSize: 10
})
const defaultQueryInfoDia = Object.freeze({
  id: '',
  keyWord: '',
  orders: 1,
  pageNum: 1,
  pageSize: 10
})
const defaultEnterpriseQueryInfo = Object.freeze({
  contactStatus: '',
  id: '',
  contactContent: '',
  quotation: ''
})
const defaultOrder = Object.freeze({
  color1: '',
  color2: '#C6C6C6'
})
export default {
  name: 'service',
  computed: {
    activeId() {
      return this.$route.query.id
    }
  },
  data() {
    //这里存放数据
    return {
      checkList: [], // 当前选择的行的数据
      templateSelection: '', //当前选择的行的id
      clueCompanyId: '',
      contactIds: [],
      contactList: [],
      totalContact: 0,
      contactQueryInfo: { ...defaultQueryInfoContact },
      contactDia: false, //企业联系人弹窗
      enterprRules: {
        contactStatus: [{ required: true, message: '请选择客户对接情况', trigger: 'change' }],
        contactContent: [{ required: true, message: '请输入联系情况', trigger: 'blur' }],
        quotation: [{ required: true, message: '请输入服务商报价', trigger: 'blur' }]
      }, //验证规则
      enterpriseQueryInfo: { ...defaultEnterpriseQueryInfo },
      options: ['对接', '未对接'],
      enterpriseDia: false, //编辑
      selectionList: [], //弹窗多选数据
      idsList: [], //弹窗多选数据的id
      tableDataList: [], //弹窗表格数据
      totalDia: 0, //弹窗分页
      queryInfoDia: { ...defaultQueryInfoDia },
      dialogVisible: false,
      headData: {}, ///头部信息
      // 表格数据
      tableData: [],
      loading: false,
      //排序字段
      orderinfo: { ...defaultOrder },
      // 分页总条数
      total: 0,
      ids: [],
      // 表格发送数据
      query: {
        id: '',
        orders: 1,
        pageNum: 1,
        pageSize: 10,
        keyWord: ''
      },
      enterpriseIntroShow: false
    }
  },

  created() {
    this.getHeadData()
    this.search()
  },
  //方法集合
  methods: {
    // 返回上级
    goLevel() {
      this.$router.go(-1)
    },
    // 限制字数
    truncatedText(val) {
      if (val.length > 10) {
        return val.slice(0, 10) + '...'
      }
      return val
    },
    singleElection(row) {
      this.templateSelection = row.id
      this.checkList = this.contactList.filter((item) => item.id === row.id)
    },
    // 推进状态
    async updateFollowUpStatus(val) {
      const query = {
        clueId: this.activeId,
        followUpStatus: val.followUpStatus,
        companyId: val.companyId
      }
      const { data: res } = await updateClueCompanyFreeze(query)
      if (res.resultCode === 200) {
        this.$message.success('修改成功')
        this.search()
      } else {
        this.$message.warning('修改失败，请稍候重试')
      }
    },
    newClueTableList(val) {
      return val.split(',')
    },
    //排序按创建时间
    clickUp1() {
      this.orderinfo = { ...defaultOrder }
      this.query.orders = 1
      this.search()
      this.orderinfo.color1 = '#0099ff'
      this.orderinfo.color2 = ''
    },
    clickDown2() {
      this.orderinfo = { ...defaultOrder }
      this.query.orders = 2
      this.search()
      this.orderinfo.color1 = ''
      this.orderinfo.color2 = '#0099ff'
    },
    // 修改推进状态
    changeFollowUpStatus(val) {
      this.updateFollowUpStatus(val)
    },
    // 企业联系人点击关闭弹窗
    contactClose() {
      this.clueCompanyId = ''
      this.contactIds = []
      this.templateSelection = ''
      this.checkList = []
      this.contactQueryInfo = { ...defaultQueryInfoContact }
      this.$refs.multipleTableCon.clearSelection()
      this.contactDia = false
    },
    // 企业联系人弹窗点击确定
    async contactSubmit() {
      const query = {
        clueCompanyId: this.clueCompanyId,
        userIds: this.templateSelection
        // userIds: this.contactIds
      }
      const { data: res } = await insertClueContacts(query)
      if (res.resultCode === 200) {
        this.$message.success('添加成功')
        this.contactClose()
        this.search()
      } else {
        this.$message.warning('添加失败，请稍候重试')
      }
    },
    //企业联系人点击
    contactClick(row) {
      this.clueCompanyId = row.id
      this.contactQueryInfo.companyId = row.companyId
      this.getContact().then((res) => {
        this.contactDia = true
      })
    },
    // 获取企业联系人的数据
    async getContact() {
      const { data: res } = await listPagePeople(this.contactQueryInfo)
      if (res.resultCode === 200) {
        this.contactList = res.data.list
        this.totalContact = res.data.total
      }
    },
    handleSizeChangeCon(val) {
      this.contactQueryInfo.pageSize = val
      this.getContact()
    },
    handleCurrentChangeCon(val) {
      this.contactQueryInfo.pageNum = val
      this.getContact()
    },
    handleSizeChange(val) {
      this.query.pageSize = val
      this.search()
    },
    handleCurrentChange(val) {
      this.query.pageNum = val
      this.search()
    },
    // 头部数据
    async getHeadData() {
      const { data: res } = await listTopById({
        id: this.activeId
      })
      if (res.resultCode == 200) {
        this.headData = res.data
      }
    },

    // table表格按钮的选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id)
    },
    async search() {
      this.query.id = this.activeId
      this.loading = true
      const { data: res } = await selectClueCompanyPage(this.query)
      if (res.resultCode == 200) {
        this.tableData = res.data.list
        this.total = res.data.total
        this.loading = false
      }
    },

    // 搜索
    changeSearchangeSearch() {
      this.query.pageNum = 1
      this.search()
    }
  }
}
</script>
<style scoped lang="less">
.contact_person {
  cursor: pointer;
  color: #4e93fb;
}
.contact_dia {
  width: 100%;
  overflow: hidden;

  .contact_dia_title {
    color: #333333;
    font-size: 14px;
    font-weight: 400;

    span {
      margin: 0 6px;
    }
  }

  .con_table {
    width: 100%;
    margin: 20px 0;
  }
}
/deep/ a {
  color: #4e93fb;
  text-decoration: underline;
}
.enterprise_dia {
  width: 100%;
  overflow: hidden;

  .txtree {
    width: 100%;
    ::v-deep .el-textarea {
      width: 400px;
    }
    ::v-deep.el-textarea__inner {
      display: block;
      resize: vertical;
      padding: 5px 15px;
      line-height: 1.5;
      box-sizing: border-box;
      width: 400px;
      font-size: inherit;
      color: #606266;
      background-color: #fff;
      background-image: none;
      border: 1px solid #dcdfe6;
      border-radius: 4px;
      transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }

  .enterprise_dia_flex {
    display: flex;
    flex-direction: row;
    align-items: center;

    ::v-deep .el-input {
      width: 230px;
    }

    span {
      display: block;
      width: 40px;
      margin-left: 12px;
    }
  }
}
.div_dia {
  width: 100%;
  overflow: hidden;

  .dia_table {
    margin: 20px 0;
    width: 100%;
  }

  .seach_box {
    display: flex;
    margin: 15px 0;
    align-items: flex-end;
    .input-with-select {
      width: 400px;
    }

    .seach {
      background-color: #448aff;
      color: #fff;
      border-radius: 0;
      border: 1px solid #448aff;
    }
  }
}
.all_context {
  padding: 16px 0px 0 0px;
  .span_tag {
    font-size: 14px;
    line-height: 20px;
    color: #4e93fb;
    margin-right: 10px;
    cursor: pointer;
  }

  .all_context_flex {
    width: 100%;
    display: flex;
    background-color: #ffffff;
    padding-right: 50px;
    border-radius: 8px;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 20px;
    align-items: center;
    .right {
      position: absolute;
      right: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .total_num {
        margin-bottom: 10px;
        font-size: 30px;
        font-weight: 550;
        color: #ff7d18;
      }
    }
  }
  .storeCenter_item_top_left {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 120px;

    .banner_box {
      position: relative;
      width: 175.55px;
      height: 115.73px;
      padding-right: 10px;
      img {
        width: 170.55px;
        height: 117.73px;
        border-radius: 4px;
        margin-right: 18px;
      }

      .activity_state {
        position: absolute;
        width: 93px;
        height: 34px;
        background: #ff7d18;
        color: #fff;
        text-align: center;
        line-height: 34px;
        border-radius: 10px 10px 10px 0px;
        top: 0px;
        left: 0px;
        transform: translate(-10px, -10px);
      }
      .one {
        background: #ff7d18;
      }
      .two {
        background: #599afb;
      }
      .three {
        background: #999999;
      }
      .four {
        background: #999999;
      }
    }

    .storeCenter_item_top_left_flex {
      flex: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .clue_num {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #6e6e6e;
        font-size: 14px;
        margin-right: 10px;
        img {
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }
      }
      .clue_name {
        font-size: 18px;
        font-weight: bolder;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        min-width: 40px;
        max-width: 300px;
        margin-right: 10px;
        -webkit-box-orient: vertical;
      }

      .mark_box {
        display: flex;
        img {
          width: 14px;
          // height: 14px;
          margin-right: 6px;
          color: #6e6e6e;
        }
        & > div {
          margin-left: 10px;
        }
      }
      .img_commer {
        display: flex;
        border: 1px solid #146aff;
        border-radius: 39px;
        .name {
          font-size: 12px;
          line-height: 20px;
          color: #146aff;
        }
        .color_img {
          background: #146aff;
          height: 20px;
          width: 25px;
          border-radius: 20px;
          position: relative;
          left: -10px;
          img {
            width: 13px;
            height: 13px;
            position: relative;
            left: 5px;
            top: 2px;
          }
        }
      }
      .img_commers {
        display: flex;
        border: 1px solid #ff9c00;
        border-radius: 39px;
        margin: 0px 10px;
        .name {
          font-size: 12px;
          line-height: 20px;
          color: #ff9c00;
        }
        .color_img {
          background: #ff9c00;
          height: 20px;
          width: 25px;
          border-radius: 20px;
          position: relative;
          left: -10px;
          img {
            width: 13px;
            height: 13px;
            position: relative;
            left: 5px;
            top: 2px;
          }
        }
      }

      h4 {
        color: #1f2d3d;
        font-size: 26px;
        font-weight: 500;
        margin: 0;
      }
      .row {
        margin-bottom: 10px;
      }

      .storeCenter_item_top_left_flex_tag {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-content: center;

        margin-bottom: 1px;

        span {
          box-sizing: border-box;
          display: block;
          font-size: 14px;
          font-weight: 400;
          border-radius: 4px;
          cursor: pointer;
        }

        .office {
          border: 1px solid #559eff;
          color: #4e93fb;
          padding: 1px 6px;
          margin-right: 10px;
          cursor: pointer;
        }

        .support {
          border: 1px solid #fd523f;
          color: #fd523f;
          margin-left: 12px;
          cursor: pointer;
        }
      }

      .address {
        font-size: 16px;
      }
    }
  }
  .storeCenter_item_top_right {
    margin-right: 20px;
  }
  .seach_box {
    display: flex;
    margin: 15px 0;
    align-items: flex-end;
    .input-with-select {
      width: 400px;
    }

    .seach {
      background-color: #448aff;
      color: #fff;
      border-radius: 0;
      border: 1px solid #448aff;
    }
  }

  .context {
    background-color: #ffffff;
    padding: 0 21px 0 30px;
    border-radius: 8px;
    .pass {
      color: #4e93fb;
      cursor: pointer;
    }
    .un_pass {
      color: #fd6161;
      cursor: pointer;
    }
    .state {
      width: 100%;
      overflow: hidden;
      height: 72px;
      border-bottom: 1px dashed #f3f3f3;
      display: flex;
      box-sizing: border-box;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        box-sizing: border-box;
        align-items: center;
        .label {
          font-size: 14px;
          color: #7c7f8e;
        }
        .item {
          color: #333333;
          font-size: 14px;
          cursor: pointer;
          padding: 15px 20px;
        }
      }
      .right {
        display: flex;
        padding-right: 25px;
        f .icon {
          width: 20px;
          height: 20px;

          img {
            width: 100%;
            height: 100%;
          }
        }
        .tex {
          margin-left: 7px;
          color: #595959;
        }
        .time {
          color: #333333;
        }
      }
      /deep/.el-radio {
        margin-right: 0;
      }
    }
    .time_search {
      height: 80px;
      display: flex;
      align-items: center;
      padding-right: 10px;
      justify-content: space-between;
      position: relative;
      .time {
        display: flex;
        box-sizing: border-box;
        align-items: center;
        position: absolute;
        left: 0;

        .label {
          font-size: 14px;
          color: #7c7f8e;
        }
        // .item {
        //   color: #333333;
        //   font-size: 14px;
        //   cursor: pointer;
        //   padding: 15px 20px;
        // }
      }
      .search {
        display: flex;
        position: absolute;
        right: 0;
        .search_input {
          width: 430px;
        }
        .search_div {
          width: 74px;
          height: 40px;
          background-color: #448aff;
          color: #fff;
          border-radius: 2px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          transform: translateX(-2px);
        }
      }
    }

    .table {
      margin-top: 20px;
      width: 100%;
      overflow: hidden;
      .imgbox {
        display: flex;
        justify-content: center;

        .img {
          width: 34px;
          height: 34px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .correlationBox {
        display: flex;
        align-items: center;
        justify-content: center;
        .correlation {
          background-color: #fd6161;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 55px;
          height: 24px;
          color: #fff;
          font-size: 12px;
        }
      }

      .enterpriseIntro {
        // width: 100%;
        // height: 100%;
        width: 376px;
        overflow: hidden; //超出隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示。
        // 控制行数
        -webkit-line-clamp: 2; //超出两行隐藏
        -webkit-box-orient: vertical; // 从上到下垂直排列子元素
        color: #333333;
        position: relative;
      }
      .enterpriseIntroShow {
        position: absolute;
        // right: 0%;
        // bottom: -23%;
        border-radius: 8px;
        // transform: translateX(50%);
        width: 376px;
        padding: 10px 7px 8px 13px;
        background-color: #fff;
        z-index: 999;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
      }

      /deep/.el-table__body-wrapper {
        overflow: visible;
      }
      /deep/.el-table {
        overflow: visible;
      }
    }
    .Pagination {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      padding: 50px 0 50px 0;
    }
  }
}
.add_select {
  .remove_pool {
    box-sizing: border-box;
    width: 348px;
    height: 150px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    box-shadow: 0px 0px 2px #d4d4d4;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    padding: 42px 0 20px 0;
    align-items: center;
    .el-icon-close {
      position: absolute;
      right: 11px;
      top: 11px;
      color: #b6b6b4;
      font-size: 18px;
      cursor: pointer;
    }
    .text {
      margin-bottom: 23px;
    }
    /deep/.el-button {
      width: 112px;
      height: 40px;
    }
  }
  /deep/.el-dialog {
    margin-top: 40vh !important;
  }
}

.type_culb {
  box-sizing: border-box;
  padding: 2px 6px;
  color: rgb(168, 162, 162);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.correlation {
  /deep/.el-dialog {
    border-radius: 4px;
    position: relative;
  }
  /deep/.el-dialog__body {
    padding: 28px 20px 16px 20px;
  }
  .correlationBox {
    .head {
      position: absolute;
      left: 0;
      top: 12px;
      // transform: translate(-20px, -50px);
      .title {
        border-left: 4px solid #448aff;
        padding-left: 15px;
        font-size: 16px;
        color: #333333;
        font-weight: 500;
      }
    }
    .context {
      .input {
        display: flex;
        justify-content: space-around;
        align-items: center;
        .label {
          position: relative;
          font-size: 14px;
          color: #333333;
          span {
            position: absolute;
            right: -9px;
            top: -2px;
            color: #f05252;
          }
        }
        /deep/.el-input {
          width: 322px;
        }
      }
      .button {
        width: 100%;
        display: flex;
        justify-content: center;
        .text {
          width: 64px;
          height: 36px;
          cursor: pointer;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 14px;
          background-color: #448aff;
          margin-top: 31px;
        }
      }
    }
  }
}
.arrUp {
  width: 0;
  height: 0;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  border-bottom: 8px solid #c6c6c6;
  cursor: pointer;
}

.arrDown {
  width: 0;
  height: 0;
  margin-top: 2px;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  border-top: 8px solid #c6c6c6;
  cursor: pointer;
}
.main {
  display: flex;
  flex-direction: column;
}
.progress {
  color: #cccccc;
  background: red;
}
</style>
